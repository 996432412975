import React, {useEffect, useState} from "react";

import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useScrollTop} from "../../../hooks";
import {ModalPortal} from "../ModalPortel";
import {ModalWrap} from "../ModalWrap";
import {clearStatus, hideLogin} from "../../../features/login/loginSlice";
import LoginForm from "./LoginForm";
import "./login.scss";
import ForgotForm from "./ForgotForm";

export const Login = () => {
    const [type,setType] = useState('login');

    const login = useAppSelector(state=>state.login);

    const dispatch = useAppDispatch();

    useScrollTop();

    const onClose = () => {
        dispatch(hideLogin());
    }

    const handleType = (changeType:string) => {
        // @ts-ignore
        dispatch(clearStatus());
        setType(changeType)
    }

    useEffect(()=>{
        return ()=>{
            setType('login');
            // @ts-ignore
            dispatch(clearStatus());
        }
    },[]);

    if(!login.show){
        return null;
    }

    return (
        <ModalPortal addClass={"login-modal"}>
            <ModalWrap closeModal={onClose}>
                <div className={"login_card "+(type)}>
                    <LoginForm
                        handleType = {handleType}
                        type = {type}
                    />
                    <ForgotForm
                        handleType = {handleType}
                    />
                </div>
            </ModalWrap>
        </ModalPortal>
    )
}