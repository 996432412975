import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
 import './index.scss';
import { BrowserRouter } from "react-router-dom";
import {init} from "./features/common/commonSlice";
import {initCommon} from "./features/login/loginSlice";
import { CookiesProvider } from 'react-cookie';

const container = document.getElementById('root')!;
const root = createRoot(container);

declare global {
    interface Window {
        _common?: any
    }
}
store.dispatch(init(window._common));
store.dispatch(initCommon(window._common));

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <CookiesProvider  defaultSetOptions={{ path: '/' }}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </CookiesProvider>
    </Provider>
  </React.StrictMode>
);
