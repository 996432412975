import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BikeCard, BikeDetail, OrderData, User} from "../../types";
import {api} from "../../service";


export const getAccessory = createAsyncThunk(
    'getAccessory',
    async ({model_id, start, end, optionStart, optionEnd}: {
        model_id: number,
        start: string, end: string,
        optionStart: string, optionEnd: string
    }) => {
        return await api.getAccessory(model_id, start, end, optionStart, optionEnd);
    }
);

export const fetchBikeList = createAsyncThunk(
    'fetchBikeList',
    async (thunkAPI) => {
        return await api.getBikeList() as BikeCard[];

    }
)

export const fetchBikeDetail = createAsyncThunk(
    'fetchBikeDetail',
    async (id: number, thunkAPI) => {
        return await api.getBikeDetail(id) as BikeDetail;
    }
)

export const sendUserAction = createAsyncThunk(
    'sendUserAction',
    async (action: string, thunkAPI) => {
        return await api.sendUserAction(action);
    }
)


export const fetchOrder = createAsyncThunk(
    'fetchOrder',
    async (order: OrderData, thunkAPI) => {
        return await api.sendOrder(order);
    }
)

export const fetchCheckBikePromo = createAsyncThunk(
    'fetchCheckBikePromo',
    async (promo_code: string, thunkAPI) => {
        return await api.checkPromoBikeCode(promo_code);
    }
)

export const fetchResetBikePromo = createAsyncThunk(
    'fetchResetBikePromo',
    async (promo_id: number, thunkAPI) => {
        return await api.resetPromoBikeCode(promo_id);
    }
)

export const fetchCheckUserEmail = createAsyncThunk(
    'fetchCheckUserEmail',
    async (user: User, thunkAPI) => {
        return await api.checkUserEmail(user);
    }
)

type Bikes = {
    list: BikeCard[],
    detail?: BikeDetail,
    orderDate: {
        start: string,
        end: string,
        duration: number,
    },
    success: number
    checkPromo: boolean
    checkPromoError: boolean
    checkEmail: boolean
    checkEmailError: boolean
    invoiceUrl: string
}
const initialState: Bikes = {
    list: [],
    detail: undefined,
    orderDate: {
        start: '',
        end: '',
        duration: 0,
    },
    success: 0,
    invoiceUrl: '',
    checkPromoError: false,
    checkPromo: false,
    checkEmailError: false,
    checkEmail: false
};


export const bikeSlice = createSlice({
    name: 'bike',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetSuccess: (state) => {
            return {...state, success: 0, invoiceUrl: ""}
        },
        processSuccess: (state) => {
            return {...state, success: 1}
        },
        updateOrderDate: (state, action: PayloadAction<{ start: string, end: string, duration: number }>) => {
            return {
                ...state,
                orderDate: {start: action.payload.start, end: action.payload.end, duration: action.payload.duration}
            }
        },
        resetDetail: (state) => {
            return {
                ...state, detail: undefined, orderDate: {
                    start: '',
                    end: '',
                    duration: 0,
                }
            }
        },
        processCheckBikePromo: (state) => {
            return {...state, checkPromo: true};
        },
        resetCheckPromoBikeError: (state) => {
            return {...state, checkPromoError: false};
        },
        resetCheckEmail: (state) => {
            return {...state, checkEmail: false, checkEmailError: false};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBikeList.fulfilled, (state, action) => {

            state.list = action.payload
        }).addCase(fetchBikeDetail.fulfilled, (state, action) => {

            state.detail = action.payload;
        }).addCase(fetchOrder.fulfilled, (state, action) => {
            // @ts-ignore
            state.success = action.payload.answer === 'success' ? 2 : -1;
            // @ts-ignore
            state.invoiceUrl = action.payload.invoiceUrl;
        }).addCase(fetchOrder.rejected, (state, action) => {
            // @ts-ignore
            state.success = -1;
        }).addCase(getAccessory.fulfilled, (state, {payload}: any) => {
            // state.orderDate=payload.order_date;
            state!.detail!.equip = payload.equip;
            state!.detail!.insurances = payload.insurances;
            state!.detail!.payment_percents = payload.payment_percents;
            state!.detail!.few = payload.few;
        }).addCase(fetchCheckBikePromo.fulfilled, (state, action) => {
            //  console.log('ac',action);
            // @ts-ignore
            if (!action.payload.promo || !action.payload.promo.code) {
                state.checkPromoError = true;
            } else {
                // @ts-ignore
                state.detail.promo = action.payload.promo;
            }
            state.checkPromo = false;
        }).addCase(fetchResetBikePromo.fulfilled, (state, action) => {
            // @ts-ignore
            if (action.payload.answer === 'ok') {
                // @ts-ignore
                state.detail.promo = {
                    type: '',
                    number: '',
                    code: '',
                    target: '',
                    target_id: 0,
                    promo_id: 0,
                    text: ''
                };
            }
        }).addCase(fetchCheckUserEmail.pending, (state, action) => {
            state.checkEmail = false;
        }).addCase(fetchCheckUserEmail.fulfilled, (state, action) => {
            //  console.log('ac',action);
            // @ts-ignore
            if (action.payload.checkEmail !== undefined) {
                // @ts-ignore
                if (action.payload.checkEmail) {
                    state.checkEmail = true;
                } else {
                    state.checkEmailError = true;
                }
            }
        })

    }


});
export const {
    resetSuccess,
    processSuccess,
    updateOrderDate,
    resetDetail,
    processCheckBikePromo,
    resetCheckPromoBikeError,
    resetCheckEmail
} = bikeSlice.actions;
export const bikeReducer = bikeSlice.reducer;
