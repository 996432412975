import React, {Dispatch, FC, Fragment, useEffect, useRef, useState} from 'react';
import {
    BikeItemPictures,
    Checkout,
    TypePanel
} from "../components/bike";
import {useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {fetchBikeDetail, resetDetail, resetSuccess, sendUserAction} from "../features/bike/bikeSlice";
import {label} from "../service";
import {useScrollTop} from "../hooks";
import {Link} from "react-router-dom";
import {BikeInfo} from "../components/bike/BikeInfo";
import {clsx} from "clsx";

export type CalType="normal"|"half"|"weekend"|undefined;

const SelectType:FC<{type:string|undefined,setType:Dispatch<React.SetStateAction<CalType>>}> = ({type,setType})=>{
    return (                    <div className={clsx("select-type-layer",type===undefined && "invisible")} >
       <TypePanel type={type} setType={setType} />

    </div>)
}

export const Bike = () => {
    let {id} = useParams();
    const success = useAppSelector(state=>state.bikes.success);
    const invoiceUrl = useAppSelector(state=>state.bikes.invoiceUrl);
    const checkPromo = useAppSelector(state=>state.bikes.checkPromo);
    const checkPromoError = useAppSelector(state=>state.bikes.checkPromoError);
    const checkEmail = useAppSelector(state=>state.bikes.checkEmail);
    const checkEmailError = useAppSelector(state=>state.bikes.checkEmailError);
    const profile = useAppSelector(state=>state.login.profile);
    const states = useAppSelector(state => state.common.states);
    const checkout_price = useAppSelector(state => state.common.params.checkout_price);

    const [type,setType] = useState<CalType>(undefined);
    // @ts-ignore
    const orderRef = useRef( React.MutableRefObject<null>);
    const orderScroll = () => orderRef.current?.scrollIntoView({behavior: 'smooth'});

    const dispatch = useAppDispatch();

    useScrollTop();

    useEffect(() => {
        dispatch(fetchBikeDetail(Number(id)));
        return () => {
            dispatch(resetSuccess());
            dispatch(resetDetail());
        }
    }, []);

    useEffect(() => {
        if (type) {
            dispatch(sendUserAction('getOrder'+(type.toUpperCase())));
        }
    },[type]);

    const item = useAppSelector(state => {
        return state.bikes.detail;
    });
    const date = useAppSelector(state => state.bikes.orderDate);

    return (
        <Fragment>
            <main className={"bike_details w-full"}>
                {item && <div className={"bike_details_wrap w-wrap"}>
                    <div className={"bike_details_breadcrumbs"}>
                        <Link to="/">{label('Home')}</Link> / <Link to="/our-motorcycles">{label('Rent a Motorcycle')} </Link> / <span>{item.name}</span>
                    </div>
                    <div className={"bike_details_item"}>
                        <div className={"bike_details_header"}>
                            <div className={"bike_details_header_model"}>
                                <div className={"bike_details_header_title"}>{item.name}</div>
                                <div className={"bike_details_header_price"}>
                                    {label('from')} €{Number(item.price_from)} / {label('Day')}
                                </div>
                            </div>
                            {!!item.avail && <div className={"bike_details_header_order"}>
                                <a className="btn_more" onClick={orderScroll}>{label('Book Now')}</a>
                            </div>}
                        </div>
                        <div className={"bike_details_body"}>
                            <div className={"bike_details_body_images"}>
                                <BikeItemPictures item={item}/>
                            </div>
                           <BikeInfo item={item} />
                        </div>
                        <div className={"bike_details_desc"}  ref={orderRef}>
                            <ul className={"bike_details_desc_list"}>
                                {
                                    item.info.split("\n").map((item, index) => {
                                        return <li key={index} className={"bike_details_desc_list_item"}>{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>}
                {item && !item.avail && <div className={"not_available w-wrap"}>
                    {label('Motorcycle')+' '+item.name+' '+label('temporary not available for rent')}
                </div>}
            </main>
            <main className="main page bike-details w-wrap">
                {item && <div className={"bike-details_wrap"}>
                    <div className={"bike_order_title"}>
                        <h1 className={"title"}>{label('Book Now')} {item.name}</h1>
                    </div>
                    {success !== 2 && <SelectType type={type} setType={setType} />}
                    <Checkout item={item}
                              success = {success}
                              invoiceUrl = {invoiceUrl}
                              checkPromo = {checkPromo}
                              checkPromoError = {checkPromoError}
                              checkEmail= {checkEmail}
                              checkEmailError= {checkEmailError}
                              selectedDays={date}
                              calType={type}
                              profile={profile}
                              states={states}
                              setType={setType}
                              checkoutPrice={checkout_price}
                    />
                </div>}
                </main>
            </Fragment>
    );
};

