import React, {FC, useMemo} from 'react';
import {BikeDetail, Schedule} from "../../../types";
import {isWorkDay, isWorkTime} from "../../../utils";
import {clsx} from "clsx";
const timeOption = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'

];

export const TimeSelectRow:FC<{label:string,day:string,value:string,
    name:string,
    schedule:Schedule[]
    onSelect:(e:React.ChangeEvent<HTMLSelectElement>)=>void
    filter?:string|undefined
    }> =
    ({label,day,value,name,onSelect,schedule,filter})=>{


    const not_work_day = useMemo(()=>{
        return !isWorkDay(day,schedule)
    },[day]);

    const timeMap:{name:string,not_work:boolean}[] = useMemo(()=>{
        const res =timeOption.map((item)=>{
            let not_work =  !isWorkTime(day,item,schedule);
            return {name:item,not_work:not_work}
        });
        if(filter ===undefined){
            return res;
        }
         return  res.filter(item=>{
            return item.name.split(":")[0]>filter.split(":")[0]
         });
    },[day,filter]);

    const not_work_time =  useMemo(()=>{
        return !isWorkTime(day,value,schedule);
    },[day,value])

    return <div className="time-s-row">
        <span className="time-label">{label}</span>
        <span className={clsx("time-day",not_work_day && 'not-work')}  >{day} {not_work_day && '*'}</span>
        <select
            className={clsx(not_work_time && "not-work")}
            name={name} value={value} onChange={onSelect}>
            <option key={0} value={""}>{`...`}</option>
            {
                timeMap.map((item)=>{
                    return  <option className={clsx(item.not_work && 'not-work' )} key={item.name} value={`${item.name}:00`}>{`${item.name}:00`}</option>
                })
            }


        </select>
    </div>
}

