import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CommonState} from "../../types/common";
import {api} from "../../service";

export const fetchHomeData = createAsyncThunk(
    'fetchHomeData',
    async (thunkAPI) => {
        return await api.getHome();

    }
)

export const fetchRoutesData = createAsyncThunk(
    'fetchRoutesData',
    async (thunkAPI) => {
        return await api.getRoutes();

    }
)

const initialState: CommonState = {
    current_language: 'en',
    contacts: {
        country: '',
        city: '',
        address: '',
        phone: '',
        email: '',
        googleCoords: '',
        siteName: '',
    },
    languages: [],
    states: [],
    popular: {model: '', price: 0, id: 0},
    external_links: {},
    params: {
        checkout_price:'day',
        roulette_enabled:'0'
    },
    title: '',
    lang_domain:false,
    mobile:false,
    my_packages:false,
}

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        init: (state, action: PayloadAction<any>) => {
            return {...action.payload}
        },
        updateTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload
        }
    },
    extraReducers: (builder) => {

    }
})

export const {init,updateTitle} = commonSlice.actions;
export const commonReducer = commonSlice.reducer;