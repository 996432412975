import React, {FC} from 'react';
import {MainInput} from "../tools/MainInput";
import {CheckoutError} from "./Checkout";
import {User} from "../../types";
import {label} from "../../service";
import {UserProfile} from "../../types/login";
import {useAppDispatch} from "../../app/hooks";
import {showLogin} from "../../features/login/loginSlice";
import {CountryItem} from "../../types/common";

interface UserDataProps {
    error: CheckoutError,
    form: User,
    onFormChange: (name:string,value:any) => void
    profile: UserProfile
    states: CountryItem[]
    checkEmailError:boolean
}

export const UserData: FC<UserDataProps> = ({error, form, onFormChange, profile,states,checkEmailError}) => {

    const dispatch = useAppDispatch();

    const handleLogin = () => {
        // @ts-ignore
        dispatch(showLogin());
    }

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
            onFormChange(e.target.name,e.target.value);
    }

    const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onFormChange(e.target.name,e.target.value);
    }

    const handleFirmSelect = (is_firm:number) => {
        onFormChange('is_firm',is_firm);
    }

    return (
        <div className={"personal_info"}>
            <div className="heading">
                {label('Personal Info')}
            </div>
            {+profile.user_id === 0 && <div className={"personal_info_subrow"}>
                <div className={"personal_info_subrow_item tab_item "+(form.is_firm===0?"active":"")} onClick={()=>handleFirmSelect(0)}>{label('New Customer')}</div>
                <div className={"personal_info_subrow_item tab_item "+(form.is_firm===1?"active":"")} onClick={()=>handleFirmSelect(1)}>{label('New Firm Customer')}</div>
                <div className={"personal_info_subrow_item link_item"} onClick={handleLogin}>
                    {label('I already have an account')}
                </div>
            </div>}
            <div className={"personal_info_item"}>
                <div className={"personal_info_item_label"}>{label('Your Name')}*<span className={"personal_info_item_label_warning"}>{label('Only Latin letters')}</span></div>
                <MainInput name={"name"} error={error.name} value={form.name} onChange={handleChangeInput}
                           placeHolder={label('Your Name')}/>
            </div>
            <div className={"personal_info_item"}>
                <div className={"personal_info_item_label"}>{label('Your Surname')}*<span className={"personal_info_item_label_warning"}>{label('Only Latin letters')}</span></div>
                <MainInput name={"surname"} error={error.surname} value={form.surname} onChange={handleChangeInput}
                           placeHolder={label('Your Surname')}/>
            </div>
            <div className={"personal_info_item"}>
                <div className={"personal_info_item_label"}>{label('Email Address')}*&nbsp;{checkEmailError && +profile.user_id === 0 && <div className={"personal_info_item_error"}>{label('This email is in our database.')}&nbsp;{label('Please login.')}</div>}</div>
                <MainInput name="email" error={error.email} value={form.email} onChange={handleChangeInput}
                           disabled={+profile.user_id > 0 && profile.mail!==''}
                           placeHolder={label('Email Address')}/>
            </div>
            <div className={"personal_info_item input-phone"}>
                <div className={"personal_info_item_label"}>{label('Phone Number')}*</div>
                <MainInput name={"phone"} error={error.phone} value={form.phone} onChange={handleChangeInput}
                           placeHolder={"000-000-000000"}/>
            </div>
            <div className={"personal_info_item input-birthday"}>
                <div className={"personal_info_item_label"}>{label('Birthday')}</div>
                <MainInput name={"birthdate"} error={error.birthdate} value={form.birthdate} onChange={handleChangeInput}
                           placeHolder={"00.00.0000"} />
            </div>
            <div className={"personal_info_item"}>
                <div className={"personal_info_item_label"}>{label('Country')}*</div>
                <div className={"input-item "+(error.country ? " error " : "")}>
                    <select
                        value={form.country_id}
                        onChange={(e) => {
                            handleChangeSelect(e)
                        }}
                        name={'country_id'}
                    >
                        {states.map((country: CountryItem) => {
                            return <option
                                key={country.id}
                                value={country.id}
                            >{country.value}
                            </option>
                        })}
                    </select>
                </div>
            </div>
            <div className={"personal_info_item"}>
                <div className={"personal_info_item_label"}>{label('City')}*</div>
                <MainInput name={"city"} error={error.city} value={form.city} onChange={handleChangeInput}
                           placeHolder={label('City') }/>
            </div>
            <div className={"personal_info_item"}>
                <div className={"personal_info_item_label"}>{label('Zip Code')}</div>
                <MainInput name={"zipcode"} error={error.zipcode} value={form.zipcode} onChange={handleChangeInput}
                           placeHolder={label('Zip Code') }/>
            </div>
            <div className={"personal_info_item"}>
                <div className={"personal_info_item_label"}>{label('Address')}*</div>
                <MainInput name={"street"} error={error.street} value={form.street} onChange={handleChangeInput}
                           placeHolder={label('Address') }/>
            </div>

            {form.is_firm===1 && <>
                <div className={"personal_info_item"}>
                    <div className={"personal_info_item_label"}>{label('Firm Name')}*</div>
                    <MainInput name={"firm_name"} error={error.firm_name} value={form.firm_name} onChange={handleChangeInput}
                               placeHolder={label('Firm Name') }/>
                </div>
                <div className={"personal_info_item"}>
                    <div className={"personal_info_item_label"}>{label('ICO')}*</div>
                    <MainInput name={"ICO"} error={error.ICO} value={form.ICO} onChange={handleChangeInput}
                               placeHolder={label('ICO') }/>
                </div>
                <div className={"personal_info_item"}>
                    <div className={"personal_info_item_label"}>{label('DIC')}*</div>
                    <MainInput name={"DIC"} error={error.DIC} value={form.DIC} onChange={handleChangeInput}
                               placeHolder={label('DIC') }/>
                </div>
                <div className={"personal_info_item"}>
                    <div className={"personal_info_item_label"}>{label('IC_DPH')}*</div>
                    <MainInput name={"IC_DPH"} error={error.IC_DPH} value={form.IC_DPH} onChange={handleChangeInput}
                               placeHolder={label('IC_DPH') }/>
                </div>
            </>}

            {+profile.user_id === 0 &&
                <div className={"personal_info_item "}>
                    <div className={"personal_info_item_label"}>{label('Password')}</div>
                    <MainInput name={"password"} error={error.password} value={form.password} onChange={handleChangeInput}
                               placeHolder={""}/>
                </div>
            }
            <div className={"personal_info_item note_item"}>
                <div className={"personal_info_item_label"}>{label('Note to order')}</div>
                <MainInput name={"note"} error={false} value={form.note} onChange={handleChangeInput}
                           placeHolder={label('Note to order')}/>
            </div>
        </div>
    );
};
